<template>
    <div>
        <div>
            <div class="text-center">
                <h1>Venta rápida</h1>
            </div>
            <div class="text-justify mt-3">
                <p>
                    Puedes generar una transacción de venta sin la necesidad de
                    asociar inventario. Además, el dinero en la caja se
                    incrementará automáticamente.
                </p>
            </div>

            <div class="d-flex flex-wrap">
                <div class="col-6 col-md-4">
                    <vs-checkbox v-model="saleNeedComments" warn>
                        <span> Agregar comentarios </span>
                    </vs-checkbox>
                </div>
                <div class="col-6 col-md-4">
                    <vs-checkbox v-model="saleNeedBarcode" warn>
                        <span> Agregar códigos de barras </span>
                    </vs-checkbox>
                </div>
            </div>

            <div class="mt-3">
                <payment-methods-checker v-model="paymentMethod">
                    <template #title>
                        <span class="mb-3">Método de pago *</span>
                    </template>
                </payment-methods-checker>
            </div>

            <div class="mt-3">
                <span>Valor de venta *</span>
                <input-money-component-2
                    v-model="priceOffer"
                    border
                    :class="backgroundColorTextArea"
                    enabled
                    label=""
                    icon="💲"
                />
            </div>

            <div v-if="saleNeedComments" class="mt-3">
                <span>Comentarios</span>
                <div>
                    <textarea
                        v-model="comments"
                        class="vs-textarea mt-2 text-lg"
                        :class="backgroundColorTextArea"
                        placeholder="Información relacionada"
                        rows="2"
                    ></textarea>
                </div>
            </div>

            <div v-if="saleNeedBarcode" class="mt-3">
                <span>
                    Códigos de barras de productos
                    <small> (Separados por ',') </small>
                </span>
                <div>
                    <array-string-selector
                        v-model="productsName"
                        class="mt-2"
                        label=""
                        enabled
                    />
                </div>
            </div>

            <div class="mt-5">
                <vs-button
                    id="create-order"
                    class="mt-3 mb-5 col-9 mx-auto"
                    border
                    gradient
                    @click="createSaleComponent"
                >
                    <span class="h3"> Crear venta </span>
                </vs-button>
            </div>
        </div>
        <vs-dialog
            v-model="isOpenModalResult"
            scroll
            overflow-hidden
            @close="closeResult"
        >
            <template #header>
                <div class="mx-4 mt-2 d-flex flex-column flex-lg-row">
                    <span class="h3"> El dinero ingresado debe ser </span>
                </div>
            </template>
            <div class="con-content">
                <div
                    class="d-flex flex-column justify-content-center align-items-center"
                >
                    <span class="text-primary display-3">
                        {{ moneyIngress | currency }}
                    </span>
                    <div></div>
                    <div v-if="moneyBack" class="d-flex flex-column text-xl">
                        <span class="">
                            Vuelto de:
                            <strong>
                                {{ moneyBack | currency }}
                            </strong>
                        </span>
                        <span class="">
                            Venta de:
                            <strong>
                                {{ saleResult.total | currency }}
                            </strong>
                        </span>
                    </div>
                </div>
                <div class="mt-4 d-flex justify-content-center">
                    <vs-button @click="showOrderPrinter = !showOrderPrinter">
                        🖨 Vale de compra
                    </vs-button>
                    <vs-button @click="closeResult">
                        💰 Registrar venta
                    </vs-button>
                    <vs-button
                        :to="
                            $enabledEcommerceOptions
                                ? `/app/orders/${selectedMerchant.merchantType}/${selectedMerchant.merchantId}/detail/${saleResult.id}`
                                : `/app/orders/detail/${saleResult.id}`
                        "
                    >
                        🐱‍💻 Detalles
                    </vs-button>
                </div>

                <div v-if="showOrderPrinter" class="mt-5">
                    <order-printer show :order="saleResult" />
                </div>
            </div>
        </vs-dialog>
        <vs-dialog
            v-model="isOpenModalMoneyBack"
            blur
            scroll
            overflow-hidden
            prevent-close
            @close="onEnterMoneyIngress"
        >
            <template #header>
                <div class="mx-4 mt-2 d-flex flex-column flex-lg-row">
                    <span class="h4">
                        Valor de venta {{ saleResult.total | currency }}
                    </span>
                </div>
            </template>
            <div
                class="con-content input-number-simple d-flex flex-column justify-content-center px-4"
            >
                <div class="py-2">
                    <strong class="h4"> Ingresa el monto con que paga </strong>
                </div>
                <div class="d-flex">
                    <div>
                        <input-money-component-2
                            v-model="moneyIngress"
                            autofocus
                            @enter="onEnterMoneyIngress"
                        />
                    </div>
                    <vs-button
                        :disabled="!isValidMoneyIngress"
                        icon
                        circle
                        @click="onEnterMoneyIngress"
                    >
                        ✔️
                    </vs-button>
                </div>

                <div v-if="!isValidMoneyIngress" class="mt-3">
                    <span class="text-warning">
                        El dinero ingresado debe ser mayor a el valor de la
                        venta.
                    </span>
                </div>
            </div>
        </vs-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import OrderPrinter from "@/components/orders/OrderPrinter.vue";
import PaymentMethodsChecker from "@/components/orders/PaymentMethodsChecker.vue";
import ArrayStringSelector from "@/components/utils/ArrayStringSelector.vue";
import InputMoneyComponent2 from "@/components/utils/InputMoneyComponent2.vue";

export default {
    name: "RecordOrder",
    components: {
        OrderPrinter,
        PaymentMethodsChecker,
        ArrayStringSelector,
        InputMoneyComponent2
    },
    data: () => ({
        comments: "",
        priceOffer: 0,
        moneyIngress: 0,
        moneyBack: 0,
        loaderInstance: null,
        isOpenModalMoneyBack: false,
        saleNeedBarcode: false,
        saleNeedComments: false,
        isOpenModalResult: false,
        showOrderPrinter: false,
        paymentMethod: "cashonpickup",
        productsName: [],
        saleResult: {
            id: 0,
            uuid: "",
            total: 20,
            createdAt: "2022-07-15T02:39:03.833Z",
            references: [],
            anonymousReferences: [],
            customer: {
                name: ""
            }
        }
    }),
    computed: {
        ...mapGetters("control", [
            "backgroundColor",
            "backgroundColorTextArea"
        ]),
        ...mapState("control", ["activeDark"]),
        ...mapState("commons", ["selectedMerchant"]),
        isValidMoneyIngress() {
            return this.moneyIngress >= this.saleResult.total;
        }
    },
    mounted() {},
    methods: {
        ...mapActions("orders", ["createSale"]),
        playSound() {
            const sound = this.$sounds.get("cash");
            sound.volume(0.4);
            sound.play();
        },
        onEnterMoneyIngress() {
            this.isOpenModalMoneyBack = false;
            this.isOpenModalResult = true;
            this.moneyBack = this.moneyIngress - this.saleResult.total;
        },
        closeResult() {
            this.isOpenModalResult = false;
            this.paymentMethod = "cashonpickup";
            this.showOrderPrinter = false;
        },
        async createSaleComponent() {
            this.loaderInstance = this.$vs.loading({ type: "radius" });
            try {
                const sale = await this.createSale({
                    paymentMethod: this.paymentMethod,
                    comments: this.comments,
                    merchantId: this.selectedMerchant.merchantId,
                    anonymousReferences: [
                        {
                            priceOffer: this.priceOffer,
                            appliedDiscountPerReference: 0,
                            quantity: 1,
                            product: {
                                name:
                                    this.productsName.join(",") ||
                                    "No determinado"
                            }
                        }
                    ],
                    sourcePriceMerchant: true,
                    merchantType: this.selectedMerchant.merchantType,
                    verifyAndDecreaseInventory: false,
                    references: []
                });

                this.saleResult = {
                    id: sale.id,
                    uuid: sale.uuid,
                    customerName: "Anónimo",
                    customer: {
                        firstName: "Anónimo"
                    },
                    createdAt: sale.createdAt,
                    paymentStatus: sale.paymentStatus,
                    paymentMethod: sale.paymentMethod,
                    total: sale.total,
                    references: [],
                    anonymousReferences: []
                };
                this.loaderInstance.close();
                this.moneyIngress = sale.total;
                this.playSound();
                this.comments = "";
                this.productsName = [];
                this.priceOffer = 0;
                if (this.paymentMethod === "cashonpickup") {
                    this.isOpenModalMoneyBack = true;
                    return;
                }
                this.isOpenModalResult = true;
                setTimeout(() => {
                    if (this.isOpenModalResult) {
                        this.isOpenModalResult = false;
                        this.closeResult();
                    }
                }, 60000);
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            }
        }
    }
};
</script>
